.navbar {
	background-color: transparent;
	min-height: 27px;
	/*border-bottom: 1px solid #979797;
  padding: 3.6% 5.55% 0 5.55%;*/
	padding: 2.8% 0 2.2%;
	/*margin: 0 auto 7.5% auto;*/
	z-index: 1050;
	transition: all 250ms ease-out;

	.container {
		position: relative;
	}

	a.btn {
		position: absolute;
		right: 0;
		opacity: 1;
		-webkit-backface-visibility: hidden;
		transition: opacity 250ms ease-out;
	}
}

.menu-active .navbar a.btn {
	opacity: 0;
}

/*.menu-active .navbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}*/

/*.shrink .navbar {
	padding: 				1.4% 0;
}
*/

h1,
.navbar-brand {
	@include text-indent;
	/*width: 197px;*/
	width: 100%;
	/*height: 23px;*/
	height: 62px;
  /*color: $brand-primary;
  fill: $brand-primary;*/
	background: url(../images/logo.png) center center no-repeat;
	background-size: contain !important;
	padding: 0;
	margin: 0;
	text-decoration: none !important;
	border: none !important;
}

.woocommerce .navbar-brand,
.page .navbar-brand {
	display: none;
	/*height: 18px;*/
	margin-top: 2.5%;
}

h1 {
	font-size: 1em;
	height: 19px;
}

.navbar-toggler {
	/*background-color: $bg-color;
	display: block;*/
	margin: 0;
  padding: 10px;
	/*position: absolute;
	top: 4px;
	left: 2.7777%;*/
	/*border-radius: 50%;
	z-index: 99999;*/
	top: 0;
	left: 4px;
	border: none;
	width: 54px;
	height: 54px;
	cursor: pointer;
  transition: all 0.4s;

	.menu-active & {
    /*background-color: #fff3e9;*/
    background-color: transparent;
	}
	
	.icon-bar {
	  background: $brand-primary;
	  width: 34px;
	  height: 1px;
	  border-radius: 0;
	  display: block;
	  text-align: center;
	  margin: 0 auto;
    transform-origin: center center;
    transition: all 300ms ease-in-out;

	  .menu-active & {
	  	margin-top: 0 !important;
	  	/*right: 23px;*/

	  	&.one {
        transform: translateY(1px) rotateZ(45deg);
      }
      &.two {
          width: 0 !important;
      }
      &.three {
        transform: translateY(-1px) rotateZ(-45deg);
      }
	  }
	}	
	.icon-bar + .icon-bar {
      margin-top: 9px;
	}
}

/* Menu */

/* Desktop Menu */
#menu-main {
	padding: 0;
	margin: 0;
	li {
		display: inline-block;
		padding: 0;
		a {
			color: $link-color;
			text-transform: uppercase;
			display: block;
			padding: 3.7em 2.5em;
		}
	}
}

/* Mobile Menu */
#navbarNav {
	background: inherit;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1020;
	display: none;
	/*opacity: 0;
	transform: translate3d(100%, 0, 0);
	transition: transform 0.4s;*/

  .container {
    height: 100%;
  }

  .row {
    margin: 0;
  }
}
.menu-active #navbarNav {
	/*opacity: 1;
	transform: translate3d(0, 0, 0);*/
}
.navbarNav {
	/*opacity: 0;*/
  padding-top: 6.15em;
	padding-bottom: 0;
  transition: opacity 0.4s ease 0.8s;

	a {
		/*font-family: $font-family-sans-serif;
    font-size: 1em;*/
		font-size: 1.444em;
	  line-height: 1.731em;
    padding: 0.25em 0;
	  display: block;
		color: $link-color;
		text-transform: lowercase;
	}

	.current_page_item a {
    color: $brand-primary;
	}

	.menu-active & {
    opacity: 1;
	}
}

/* MEDIA QUERIES */

  @media screen and (pointer: fine) and (min-width: 992px) {

    .navbar-toggler:hover .icon-bar + .icon-bar {
      margin-top: 6px;
      transition: all 0.3s ease-in-out;
    }
    .menu-active .navbar-toggler:hover .icon-bar + .icon-bar {
      margin-top: 4px;
    }

  }

	@include media-breakpoint-up(lg) {

    /*.navbarNav a {
      font-size: 1.364em;
    }*/

		h1 {
			margin-top: 5em;
			margin-bottom: 3.55em;
		}


		

	}

	@include media-breakpoint-down(lg) {

		/*h1 {
			margin-bottom: 8.5%;
		}*/

	}	

	@include media-breakpoint-up(xl) {

		/*.navbarNav a {
		  font-size: 4em;
		}*/
	}

	/* Mobile (landscape) // bootstrap devices more than 768px ----------- */
	@media (any-hover: hover) {

		/*.navbarNav a:hover {
		  color: $brand-primary;
		}*/

	}

	@include media-breakpoint-up(md) {

		/*.navbar-brand {
		  font-size: 1em;
		}

    .navbar-brand {
      width: 197px;
      height: 23px;
    }*/
		
	}
	@include media-breakpoint-down(md) {

    /*body.menu-active {
      overflow: hidden;
    }

    .navbar {
      position: absolute;
    }

    #navbarNav {
      overflow-y: scroll;
    }

    .navbar-brand {
      margin-top: 8px;
    }*/

		.navbar {
			min-height: 54px;
			padding: 0;
		}

		h1 {
			margin-top: 4%;
			margin-bottom: 7%;
		}
		
	}

		

	/* Mobile // bootstrap devices below 768px ----------- */
	@include media-breakpoint-up(sm) {

		/*.navbar {
		  padding: 5% 0 4%;
		}*/

	}

	@include media-breakpoint-down(sm) {

		.navbar-brand {
			height: 16px;
			margin-top: 1.5%;
		}

		/*.navbar .search-form a.search-submit,
		.navbar a.btn {
			right: 25px;
			line-height: 1;
			padding: .5em .775em .45em;
		}*/

	}

	@include media-breakpoint-up(lg) {

		/*.navbar {
		  padding: 4.75% 0 4.75%; 
		}*/

		h1 {
			height: 50px;
		}

	}

	@media only screen and (min-width: 1500px) {

		/*#menu-main {
			justify-content: space-between;
			display: flex;
			max-width: 56%;
			li {
				a {
					padding: 2px 0.85em;
				}
			}
			li:first-child a {
				padding-left: 0;
			}
		}*/

	}

	@media only screen and (max-height: 960px) {

		/*.navbarNav  a {
		  font-size: 2.615em;
		  line-height: 1.295;
		}*/

	}

	@media screen and (max-width: 320px) {

		/*.navbarNav  a {
		  font-size: 2em;
		}*/

	}

	@media screen and (max-device-height: 480px) {

		/*.navbarNav  a {
		  font-size: 1.615em;
		}*/

	}