#contact {
  background-color: #fff;
  padding: 7% 0 2.25%;
}


/***MEDIA QUERIES***/

/* Mobile // bootstrap devices below 768px ----------- */
@include media-breakpoint-down(sm) {

  #contact {
    padding: 15% 0 5%;
  }


}

/* Tablet // bootstrap devices 768px and over ----------- */
/*
@include media-breakpoint-up(md) {

}
*/