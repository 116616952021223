/** FONTS */

/* @import must be at top of file, otherwise CSS will not work */
//@import url("//hello.myfonts.net/count/37a97c");
//@font-face {font-family: 'Campton-Book';src: url('../fonts/37A97C_0_0.eot');src: url('../fonts/37A97C_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/37A97C_0_0.woff2') format('woff2'),url('../fonts/37A97C_0_0.woff') format('woff'),url('../fonts/37A97C_0_0.ttf') format('truetype');}
//@font-face {font-family: 'Campton-Medium';src: url('../fonts/37A97C_1_0.eot');src: url('../fonts/37A97C_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/37A97C_1_0.woff2') format('woff2'),url('../fonts/37A97C_1_0.woff') format('woff'),url('../fonts/37A97C_1_0.ttf') format('truetype');}

@font-face {
	font-family: "LL Brown Regular";
	src: url("../fonts/lineto-brown-regular.eot");
	src: url("../fonts/lineto-brown-regular.eot?#iefix") format("embedded-opentype"),
	url("../fonts/lineto-brown-regular.woff2") format("woff2"),
	url("../fonts/lineto-brown-regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "LL Brown Bold";
	src: url("../fonts/lineto-brown-bold.eot");
	src: url("../fonts/lineto-brown-bold.eot?#iefix") format("embedded-opentype"),
	url("../fonts/lineto-brown-bold.woff2") format("woff2"),
	url("../fonts/lineto-brown-bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

/** GENERAL LAYOUT */
	*:focus,
	button:focus {
	    outline: none;
	}

	body {
		font-family: $font-regular;
		font-weight: 400;
		font-smooth: always;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing:grayscale;
		font-size: 12px;
		background-color: $bg-color;
		color: $text-color;
		letter-spacing: 0.7px;
		text-align: center;
		border: 40px solid #fff;
	}
	body.menu-active {
		/*overflow: hidden;*/
	}
		
	h1, h2, h3, h4, h5, h6,
	h1>a, h2>a, h3>a, h4>a, h5>a, h6>a {
		font-family: 			$font-regular;
		font-weight: 			400;
		line-height: 			1.278;
	}

	h1,
	h2 {
		font-size: 				1em; /*12px*/
		letter-spacing: 	1px;
		text-transform: 	uppercase;
	}

	h2 {
		margin-bottom: 		12.25%;
	}

	h3 {
		font-size: 				1em;
	}

	h4 {
		color: 						$brand-primary;
		font-size: 				1em;
		letter-spacing: 	1px;
		margin: 					0 0 1.5em;
	}

	/* unused */
	h5,
	h6 {
		font-size: 				1em;
	}

	.smaller {
		font-size: 				0.833em; /*15px*/
		line-height: 			1.267;
	}

	.larger {
		font-size: 				1.250em; /*15px*/
		line-height: 			1.35;
	}

	blockquote {
		font-size: 				1.167em; /*21px*/
		line-height: 			1.286;
		border: 					none;
		padding: 					0 0 1.2em;
		margin: 					0;
		letter-spacing: 	0.5px;
	}

	strong, b {
		font-family: 			$font-bold;
		font-weight: 			600;
	}
	
	p {
		padding: 					0 0 1.55em 0;
		margin: 					0;
	}
	.smaller p {
		padding: 					0 0 0.9em;
	}

	ol, ul {
	  padding-left:			0;
	  margin-bottom: 		1.25em;
	}
	li {
	  padding: 					0 0 2px;
		list-style: 			none;
	}

	img {
		max-width: 				100%;	
		height: 					auto;
	}

	.img-responsive,
	.grid img {
		width: 100%;
		height: auto;
	}
	
	i {
		font-style: normal;
	}
	
	a, a:link, a:visited, a:active {
		color: $text-color;
		outline: none !important;
		text-decoration: none;
		opacity: 1;
		transition: opacity 250ms ease-in-out;
	}

	.alt-bg a {
		color: 					#fff;		

		&:hover {
			border-bottom: 		1px solid #fff;
		}
	}
	/*a img {
	  opacity: 			1;  
	  transition: 		opacity 300ms ease-out;
	  -webkit-backface-visibility: hidden;
	}		  
	a:hover img {
	  opacity: 			0.9;  
	} */
	
	small, 
	.small {
	  font-size: 		12.5px;
		font-family: $font-bold;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	
	.accent {
	  color: $brand-primary;
	}
	.light {
		color: #fff;
		a {
			color: #fff;
		}
	}
	.dark {
		color: $text-color;
		a {
			color: $text-color;
		}
	}
	.alt {

	}

	.border-top {
	  border-top: 	1px solid #ccc;
	  padding-top:	5.4%;
	}
	
	.border-left {}

	.border-left:before {
	  content: 			"";
	  position: 		absolute;
	  z-index: 			0;
	  top: 				0;
	  left: 			20px;
	  bottom: 			0;
	  border-left: 		1px solid #ccc;
	}
	
	.border-right {
		border-right: 	1px solid #ccc;
	}
	
	.border-bottom {
		border-bottom: 	1px solid #ccc;
		padding-bottom: 3.6144%;
	}
	hr {
	    margin-top: 0;
	    margin-bottom: 3.36% !important;
	    border-top: 1px solid #ccc;
	    width: 96.56%;
	}

	.testimonial-author {
		font-size: 		0.846em;
		line-height: 	1.364em;
	}
	
	.embed-container { 
		position: 		relative; 
		margin: 		0.25em 0;
		padding-bottom: 56.25%;
		height: 		0;
		overflow: 		hidden;
		max-width: 		100%;
		margin-bottom:	10px;
		transition: 	all 300ms ease;
	} 	
	.slick .embed-container {
		margin:			0;
	}
	.embed-container iframe,
	.embed-container object,
	.embed-container embed { 
		position: 		absolute;
		top: 			0;
		left: 			0;
		width: 			100%;
		height: 		100%;
		cursor: 		pointer;
	}	

	/*.no-gutter {
		margin-left:	0;
		margin-right:	0;
	}*/
	.no-gutter > [class*='col-'],
	.no-gutter[class*='col-'] {
		padding-left:	0;
		padding-right:	0;
	}	
	/*.no-gutter-col {
		padding: 		0;
	}*/

	.columns {
	   	font-weight:  	300;
	   	min-height: 	initial;
	}

	.more {
		font-family: $font-bold;
		font-size: 12.5px;
		line-height: 13px;
		letter-spacing: 1px;
		text-transform: uppercase;
		display: inline-block;
	}
	.more:after {
		content: ' More +';
	}
	.active .more:after {
		content: ' Less -';
	}

	.hidden {
		display: 		none !important;
	}

/** ACCORDION TOGGLES & TABS **/
	#accordion {
		padding-bottom:	1.5%;
	}
	#accordion article {
		overflow: hidden;
		border-bottom: 1px solid #979797;
	}
	#accordion article:last-child {
		border-bottom: none;
	}
	.card-title {
		cursor: pointer;
		margin: 0;
	}
	.card-body {
		text-align: left;
		padding: 2em 0 0.2em;
	}
	/*article.tab {
		position:		relative;
		border-top: 	1px solid #ccc;
	}
	article.tab:first-of-type,
	article.tab:last-of-type {
		border-top: 	none;
	}*/
	/*#accordion h3 {
		margin: 		0;
	}
	#accordion h3 a {
		display: 		block;
		padding: 		1.1em 0;
	}
	#accordion h3 a:after {
		content: 		"+";
		display: 		inline-block;
		float: 			right;
		padding-right:	3px;
		transition: 	all .35s ease-in-out;
	}
	#accordion h3 a[aria-expanded="true"]:after {
		transform: 		rotate(45deg);
	}*/

/** PRELOADER **/
	.preloader {
	    background: 	#fff;
	    position: 		fixed;
	    top: 			0;
	    bottom: 		0;
	    left: 			0;
	    right: 			0;
	    height: 		100%;
	    width: 			100%;
	    z-index: 		1000000;

		display: none !important;
	}

/** VIDEO **/
	#player, .fallback, video, .video-wrapper video {
		background-color: #FFFFFF !important;
		margin: 		0 !important;
		display: 		block !important;
	}	
	
	video {
		background-size: contain;
		width: 			100%;
		height: 		100%;
	}
		
	video:-webkit-full-page-media,
	video:-moz-full-page-media {
		margin-top: 	0 !important;
		margin-bottom: 	0 !important;
	}

/** LIGHTBOX **/
	/* Ari Fancy Lightbox */
	.fancybox-image, .fancybox-spaceball {
	    padding: 3% 5% !important;
	}
	.fancybox-show-infobar .fancybox-infobar {
	    position: fixed;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    width: 100%;
	}
	.fancybox-button, 
	.fancybox-toolbar,
	.fancybox-infobar,
	.fancybox-infobar__body {
	    background: transparent !important;
	    visibility: visible !important;
	    display: block !important;
	}
	.fancybox-navigation {
	    visibility: visible !important;
	    display: block !important;
	}
	.fancybox-navigation .fancybox-button svg {
		display: none !important;
	}
	.fancybox-infobar,
	.fancybox-infobar__body {
		display: none !important;
	}
	.fancybox-button {
	    width: 60px !important;
	    height: 60px !important;
	    line-height: 60px !important;
	    color: $dark-color !important;
	}
	.fancybox-button:hover {
	    opacity: 0.75 !important;
	}
	/*.fancybox-infobar .fancybox-button {
		width: 30px !important;
	    height: 18px !important;
	    margin: 0 20px 25px 20px  !important;
	    background-size: contain !important;
	    background-repeat: no-repeat !important;
	    line-height: 30px !important;
	}*/
	/*.fancybox-button--left,
	.fancybox-button--right {
	    background-image: none !important;
	}
	.fancybox-button:after, 
	.fancybox-button:before {
	    opacity: 0 !important;
	}*/
	.fancybox-button--close:after, 
	.fancybox-button--close:before {
	    height: 3px !important;
	    width: 26px !important;
	    top: calc(50% - 1px) !important;
	    left: calc(50% - 14px) !important;
	    border-color: $dark-color !important;
	    background-color: $dark-color !important;
	    color: $dark-color !important;
	    opacity: 1 !important;
	}
	/*.fancybox-arrow::after {
	    background-color: black !important;
	    filter: invert(100%);
	}*/
	/*a.ari-fancybox {
		background: #e1e1e1;
		display: block;
		position: relative;
	}*/
	/*a.ari-fancybox:hover:before {
		content: "";
		background: url('../images/icon-magnify.png') no-repeat;
		background-size: 54px 53px;
		width: 54px;
		height: 53px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -27px;
		margin-top: -27px;
		z-index: 10;
	}*/	
	/*a.ari-fancybox:hover:after {
	    content: "View larger";
		display: block;
		position: absolute;
		bottom: 1em;
		right: 0.5em;
		color: #111;
		transform: rotate(-90deg);
		transform-origin: 45% -95%;
		z-index: 10;
	}*/	
	a.ari-fancybox:hover img {
	    opacity: 0.98;	
	}
	.fancybox-slide>.fancybox-loading {
	    display: none !important;
	}		
	@media (max-width: 767px) {
		.fancybox-button {
		    width: 48px !important;
		    height: 44px !important;
		    line-height: 44px !important;
		}
		.fancybox-button--close:after, 
		.fancybox-button--close:before {
		    width: 24px !important;
		    top: calc(50% - 1px) !important;
		    left: calc(50% - 12px) !important;
		}
	}

/** ANIMATIONS & TRANSITIONS */
/*
	.rotate {
		-ms-transform: 			rotate(90deg);
    	-webkit-transform: 		rotate(90deg);
    	transform: 				rotate(90deg);
		-ms-transform-origin: 		15% 40%;
		-webkit-transform-origin: 	15% 40%;
		transform-origin: 			15% 40%;
		display: 				inline-block;
	}	
	.do-animation {
	  	transform: translate3d(0,150px,0);
	  	animation: show 1.5s ease forwards;
	  	opacity: 0.1;
	}
	.do-animation:nth-child(odd) {
	  	animation-duration: 1.1s;
	}
	.do-fade {
	  	animation: fade 1.5s ease forwards;
	  	opacity: 0.1;
	}
	.do-zoom {
	  	transform: translate3d(0,100px,0) scale(0.8,0.8);
	  	animation: zoom 0.75s ease forwards;
	  	opacity: 0.1;
	}
	.animate-block:nth-child(even) {
		animation-duration: 1.5s !important; 
	}
	.animate-block:nth-child(odd) {
		animation-duration: 1.1s !important; 
	}
	
	.v3 .animate-block:nth-child(1n),
	.grid .grid-item:nth-child(1n),
	.two-half-cols .animate-block:nth-child(1n) {
		animation-duration: 0.6s !important;
	}
	.v3 .animate-block:nth-child(2n),
	.grid .grid-item:nth-child(2n),
	.two-half-cols .animate-block:nth-child(2n) {
		animation-duration: 1s !important;
	}
	.v3 .animate-block:nth-child(3n),
	.grid .grid-item:nth-child(3n),
	.two-half-cols .animate-block:nth-child(3n) {
		animation-duration: 1.4s !important;
	}
	.already-visible {
	  transform: translate3d(0,0,0) scale(1,1); 
	  animation: none;
	  opacity:1;
	}

	@keyframes show {
	  to { transform: translate3d(0,0,0); opacity:1; }
	}
	@keyframes zoom {
	  to { transform: translate3d(0,0,0) scale(1,1); opacity: 1; }
	}
	@keyframes fade {
	  to { opacity:1; }
	}
*/

/*** MEDIA QUERIES ***/

	/* MIN WIDTH */	

	/* Mobile (portrait) // bootstrap extra small devices less than 576px ----------- */
	@include media-breakpoint-up(xs) {}
	/* No media query since this is the default in Bootstrap*/

	/* Mobile (landscape) // bootstrap small devices 576px and up ----------- */
	@include media-breakpoint-up(sm) {

		/*body {
		  font-size: 16px;
		}*/

	}

	/* Tablet // bootstrap medium devices 768px and up ----------- */
	@include media-breakpoint-up(md) {

		/*body {
		  font-size: 17px;
			line-height: 1.278;
		}*/

	}

	/* Laptop // bootstrap large devices 992px and up ----------- */
	@include media-breakpoint-up(lg) {


		/*body {
		  font-size: 18px;
		}*/

		/*
		h1 {
		  font-size: 4em; 
		}

		h2,
		.testimonial {
			font-size: 			2.9em; 
			line-height: 		1.086;
		}
		h2 {
			margin-top: -0.11em;
			margin-bottom: 0.65em;
		}

		h3 {
		  font-size: 			2em;
		  line-height: 			1.125em;
		  margin-bottom: 		0.63em;
	    }

	    .grid h3 {
	  	  font-size: 			1.6em;
	    }

		h4, 
		.larger,
		.testimonial-author {
			font-size: 			1.25em; 
			line-height: 		1.4;
		}
		h5,
		h6 {
			font-weight: 		600;
			font-size: 			1em;
		}

		small,
		.smaller {
			font-size: 			0.9em;
			line-height: 		1.556;
		}
		
		blockquote {
			font-size: 			1.2em;	
			line-height: 		1.167;		
		}

		p {
			margin: 			0 0 0.45em 0;	
		}
		*/

	}

	/* Desktop // bootstrap extra large devices 1200px and up ----------- */
	@include media-breakpoint-up(xl) {

		h2 {

		}

	}

	@media screen and (min-width: 1640px) {


	}

	/* MAX WIDTH */	

	/* Mobile (portrait) // bootstrap extra small devices less than 576px ----------- */
	@include media-breakpoint-down(xs) {}

	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {}

	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-down(md) {}

	/* Laptop // bootstrap large devices less than 1200px ----------- */
	@include media-breakpoint-down(lg) {

		h2 {

		}

	}

	/* Desktop // bootstrap extra large devices 1200px and up ----------- */
	/* No media query since the extra-large breakpoint has no upper bound on its width */

	/* MIN & MAX WIDTH */

	/* Mobile (portrait) // bootstrap extra small devices (max-width: 575px) ----------- */
	@include media-breakpoint-only(xs) {}
	/* No media query since this is the default in Bootstrap*/

	/* Mobile (landscape) // bootstrap small devices (min-width: 576px) and (max-width: 767px) ----------- */
	@include media-breakpoint-only(sm) {}

	/* Tablet // bootstrap medium devices (min-width: 768px) and (max-width: 991px) ----------- */
	@include media-breakpoint-only(md) {}

	/* Laptop // bootstrap large devices (min-width: 992px) and (max-width: 1199px) ----------- */
	@include media-breakpoint-only(lg) {}

	/* Desktop // bootstrap extra large devices (min-width: 1200px) ----------- */
	@include media-breakpoint-only(xl) {}



	/* Desktop // XX large ----------- */
	@media only screen and (min-width: 1941px) {
		

	}
	
	/* Desktop // X large ----------- */
	@media only screen and (min-width: 1531px) and (max-width: 1940px) {
		

	}
	
	/*@media only screen and (min-width: $screen-lg-min) and (max-width: 1650px) {*/
	@media only screen and (max-width: 1530px) {
		
	}

	@media screen and (max-height: 900px) {
		

	}

	/* Laptop // bootstrap large devices less than 1200px ----------- */
	@include media-breakpoint-down(lg) {	
	

	}	
	
	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-down(md) {

		body {
			border-width: 14px;
			overflow-x: hidden;
		}

		.bg-img {
			padding-bottom: 75%;
		}

		.split .bg-img {
			padding-bottom: 75vh;
		}



	}

	@media screen and (pointer: fine) and (min-width: 992px) {

		/** ANIMATIONS & TRANSITIONS */
		.rotate {
			-ms-transform: 			rotate(90deg);
	    	-webkit-transform: 		rotate(90deg);
	    	transform: 				rotate(90deg);
			-ms-transform-origin: 		15% 40%;
			-webkit-transform-origin: 	15% 40%;
			transform-origin: 			15% 40%;
			display: 				inline-block;
		}	
		.do-animation {
		  	/*transform: translate3d(0,150px,0) scale(0.7,0.7);*/
		  	transform: translate3d(0,150px,0);
		  	animation: show 1.5s ease forwards;
		  	opacity: 0.1;
		}
		.do-animation:nth-child(odd) {
		  	animation-duration: 1.1s; /* stagger */
		}
		.do-fade {
		  	animation: fade 1.5s ease forwards;
		  	opacity: 0.1;
		}
		.do-zoom {
		  	transform: translate3d(0,100px,0) scale(0.8,0.8);
		  	animation: zoom 0.75s ease forwards;
		  	opacity: 0.1;
		}
		.animate-block:nth-child(even) {
			animation-duration: 1.5s !important; /* stagger */
		}
		.animate-block:nth-child(odd) {
			animation-duration: 1.1s !important; /* stagger */
		}
		
		.v3 .animate-block:nth-child(1n),
		.grid .grid-item:nth-child(1n),
		.two-half-cols .animate-block:nth-child(1n) {
			animation-duration: 0.6s !important; /* stagger */
		}
		.v3 .animate-block:nth-child(2n),
		.grid .grid-item:nth-child(2n),
		.two-half-cols .animate-block:nth-child(2n) {
			animation-duration: 1s !important; /* stagger */
		}
		.v3 .animate-block:nth-child(3n),
		.grid .grid-item:nth-child(3n),
		.two-half-cols .animate-block:nth-child(3n) {
			animation-duration: 1.4s !important; /* stagger */
		}
		.already-visible {
		  transform: translate3d(0,0,0) scale(1,1); 
		  animation: none;
		  opacity:1;
		}

		@keyframes show {
		  to { transform: translate3d(0,0,0); opacity:1; }
		}
		@keyframes zoom {
		  to { transform: translate3d(0,0,0) scale(1,1); opacity: 1; }
		}
		@keyframes fade {
		  to { opacity:1; }
		}

	}

	@media screen and (pointer: coarse) and (max-width: 991px) {

		/** ANIMATIONS & TRANSITIONS */
		.animate-block {
		  opacity: 0.1;
		}
		.do-animation {
		  opacity: 0.1;
		  animation: fade 1.5s ease forwards;
		}
		.already-visible {
		  opacity: 1 !important;
		}
		@keyframes fade {
		  to { opacity:1; }
		}

	}
	
	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {	

		body {

		}

		.page-loader{
		  display: none !important;
		}

		.animate-block:nth-child(even),
		.v3 .animate-block:nth-child(2n),
		.two-half-cols .animate-block:nth-child(2n),
		.v3 .animate-block:nth-child(3n),
		.two-half-cols .animate-block:nth-child(3n) {
		  animation-duration: 1.1s !important;
		}

		/*h1,
		blockquote,
		.text blockquote {
			font-size: 1.7em;
		}*/

		blockquote {
			font-size: 1em;
		}



	}	

	/* Mobile (portrait) // bootstrap extra small devices less than 576px ----------- */
	@include media-breakpoint-down(xs) {

	}

	@media only screen and (max-height: 618px) { 

	}

	@media only screen and (max-width: 360px) { 

	}