/*
 * jQuery FlexSlider v2.6.3
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: 'flexslider-icon';
  src: url('fonts/flexslider-icon.eot');
  src: url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flexslider ul,
.flexslider li {
  font-size: 1em;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0 0 1.84em 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
  transition: opacity 300ms;
}
.flexslider .slides img {
  width: 100%;
  display: block;
  opacity: 1;
  transition: opacity 300ms;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  /*background: #fff;*/
  position: relative;
  zoom: 1;
}
/*.flexslider ul>li:before,
.content ul>li:before {
  display: none;
}*/
.flexslider .slides {
  zoom: 1;
}
/*.content .flexslider ul>li {
  margin: 0;
  padding: 0;
}
.content .flexslider ul>li.container-fluid {
  padding: 0 10px;
}
.main section .flexslider ul>li {
  position: static;
}
.content .flexslider ul>li:before {
  display: none;
}*/
.flexslider ul>li.container-fluid {
  padding: 0 10px;
}
.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}
.flex-viewport {
  max-height: 2000px;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 1;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  /*text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);*/
  transition: all 0.3s ease-in-out;
}
.flex-direction-nav a:before {
  /*font-family: "flexslider-icon";*/
  font-size: 40px;
  display: inline-block;
  /*content: '\f001';*/
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;
  /*text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);*/
}
.flex-direction-nav a.flex-next:before {
  /*content: '\f002';*/
}
.flex-direction-nav .flex-prev {
  /*left: -50px;*/
  left: 10px;
}
.flex-direction-nav .flex-next {
  /*right: -50px;*/
  right: 10px;
  text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
  /*opacity: 0.7;
  left: 10px;*/
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  /*opacity: 1;*/
}
.flexslider:hover .flex-direction-nav .flex-next {
  /*opacity: 0.7;
  right: 10px;*/
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  /*opacity: 1;*/
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a.flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  left: 0;
}
.single .flex-control-nav {
  text-align: right;
  right: 1.85em;
  left: auto;
  bottom: 0.58em;
}
.flex-control-nav li {
  margin: 0 8px 0 0;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.single .flex-control-nav li {
  margin: 0 0 0 8px;
}
.flex-control-paging li a {
  @include text-indent;
  width: 9px;
  height: 9px;
  display: block;
  overflow: hidden;
  background: #b3b3b3;
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-indent: -9999px;
  /*box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);*/
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: #bfbfbf;
  background: rgba(0, 0, 0, 0.25);
}
.flex-control-paging li a.flex-active {
  background: #bfbfbf;
  background: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

/* ====================================================================================================================
 * TESTIMONIALS
 * ====================================================================================================================*/
@media screen and (max-width: 992px) {
  
  .testimonials {
    padding-top: 1.8em;
  }

}

@media screen and (min-width: 992px) {
  
  .testimonials h2 {
    margin-bottom: 0.3em;
  }

  .awards .flex-control-nav {
    bottom: -8px;
    left: 6px;
  }

}
/*
.testimonials .flex-nav-prev,
.testimonials .flex-nav-next {
  display: none;
}
.testimonials .flex-nav-next {
  padding: 2em 0 !important;
}
.testimonials .flex-nav-next a {
  -webkit-backface-visibility: hidden;
  transform: rotate(45deg);
  color: #fff;  
  width: 30px;
  height: 30px;  
  position: static;
  margin-left: -8px;
  
  &:hover {
    margin-left: -6px; 

    &:before {
      width: 28px;
    }
  }

  &:before, 
  &:after {
    content: "";
    position: absolute;
    display: block;
  }
      
  &:before {    
    color: #c2c2c2;
    right: 2px;
    top: 1px;
    width: 26px;
    height: 2px;
    transform: rotate(-45deg);
    transform-origin: right top;
    box-shadow: inset 0 0 0 10px;
  }
      
  &:after {
    width: 10px;
    height: 10px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #c2c2c2;
    top: 0px;
    right: 0px;
  } 
}
*/

/* ====================================================================================================================
 * ARROW CURSOR SLIDER
 * ====================================================================================================================*/
.gallery .flex-direction-nav a.flex-prev,
.gallery .flex-direction-nav a.flex-next {
    width: 25%;
    height: 100%;
    top: 0;
    margin-top: 0;
    background: none;
}
.gallery .flex-direction-nav a.flex-prev {
    left: 50%;
    background: url("../images/arrow-white.svg") 88% 52% no-repeat;
    transform: rotate(180deg);
    /*cursor: url("../images/arrow-left.png") 0 0, pointer;
    cursor: -webkit-image-set(url("../images/arrow-left.png") 1x, url("../images/arrow-left@2x.png") 2x) 0 0, pointer; */
    text-indent: -99999px;
}
.gallery .flex-direction-nav a.flex-next {
    right: 0;
    background: url("../images/arrow-white.svg") 88% 48% no-repeat;
    /*cursor: url("../images/arrow-right.png") 0 0, pointer;
    cursor: -webkit-image-set(url("../images/arrow-right.png") 1x, url("../images/arrow-right@2x.png") 2x) 0 0, pointer;*/
    text-indent: 99999px;
}
.gallery .flex-direction-nav a:before{
    display: none;
}

/* ====================================================================================================================
 * HOME SLIDER
 * ====================================================================================================================*/
.home .flexslider {
  padding-bottom: 0;
  margin-bottom: 0;
}
.flexslider h1 {
  min-height: 125px;
}
.flexslider h1 span {
  display: block;
  opacity: 0;
  transform: translate(0, -100%);
  /*transition: all 150ms ease 0.35s;*/
  /*transition: transform 0.25s cubic-bezier(0.770, 0.000, 0.175, 1.000) 0.25s, opacity 0.25s linear 0.25s;*/
  transition: transform 0.175s ease-out 0.275s, opacity 0.175s linear 0.275s;
}
.flexslider .flex-init-slide h1 span {
  opacity: 0;
  transform: translate(0, 100%);
}
.flexslider .flex-slide h1 span {
  opacity: 1;
  transform: translate(0, 0%);
}
/*.flexslider .flex-active-slide h1 span {
  opacity: 1;
  transform: translate(0, 0%);
}*/


/* ====================================================================================================================
 * PROJECT SLIDER
 * ====================================================================================================================*/
.single .row.slideshow {
  margin: 0 0 1%;
  overflow: hidden;
  position: relative;
}
.single .flexslider.gallery {
  position: static;
  padding: 0 0 0.5em;
}
.single .gallery .flex-viewport {
  overflow: visible !important;
}
.single .gallery .slides li {
  position: relative !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.single .gallery .slides li.portrait {
  background-size: contain;
  background-position: center left;
}
.single .gallery .slides li:last-child:after {
  display: none;
}
/*.single .gallery li.portrait img {
  width: auto;
  height: 0;
  padding-bottom: 66.66%;
}*/
.single .gallery .slides li.portrait:after {
  content: "";
  background-image: inherit;
  background-size: cover;
  background-position: center center;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}
.single .gallery .slides li:not(.flex-active-slide),
.single .gallery .slides li:not(.flex-active-slide) img {
  opacity: 0.1;
}
.single .gallery .slides li.portrait:not(.flex-active-slide) {
  background-size: contain;
}
.single .gallery .slides li.portrait:not(.flex-active-slide):after {
  opacity: 0;
}

  /*.single-projects .flexslider.gallery {
    height: 0 !important;
    padding-bottom: 66.6118%;
    overflow: hidden !important;
  }
  .single-projects .flexslider.gallery ul>li {
      background-color: #efefef;
  }
  .single-projects .flexslider.gallery .slides .landscape img {
      height: auto !important;
      max-width: 100%;
  }
  .single-projects .flexslider.gallery .slides .portrait img {
      width: auto !important;
      max-width: 100%;
      max-height: 100%;
  }
  */



/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  /*.flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }*/
  /*.testimonials .flex-nav-next {
    padding: 1em 0 1em !important;
  }*/
  .flex-control-nav {
    bottom: -0.5em;
    right: 0;
  }
  /*.home .flexslider {
    padding-bottom: 6.5%;
  }
  .home .flexslider h6 {
    margin: 18px 0 0 20px;
    width: 50%;
    display: inline-block;
    font-size: 0.725em;
  }
  .home .flex-control-nav {
    bottom: 1.1em;
    right: 0;
    width: 50%;
  }*/
  .single-post .flex-control-nav li {
    margin: 0 0 0 6px;
  }
  .single-post .flex-control-paging li a {
    width: 8px;
    height: 8px;
  }
  

}



/* SLICK SLIDER */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    padding: 0;
    opacity: 0.1;
    transition: opacity 300ms;
}
.slick-slide.slick-current {
    opacity: 1;
}
.slick-slide img {
    display: block;
    width: auto;
    height: 34vw;
}
.slick-slide > div {
    width: 60.5vw;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Preloader */
/*
.slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}
*/

/* Icons */
/*
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
*/

/* Arrows */
/*
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
}
.slick-prev {
    left: 25px;
}
.slick-prev:before {
    content: '←';
}
.slick-next {
    right: 25px;
}
.slick-next:before {
    content: '→';
}
*/

/**/

.slick-prev,
.slick-next {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    margin-top: 0;
    background: none;    
    z-index: 100;
    border: none;
    outline: none;
}
.slick-prev {
    left: 0;
    cursor: url("../images/arrow-left.png") 0 0, pointer;
    cursor: -webkit-image-set(url("../images/arrow-left.png") 1x, url("../images/arrow-left@2x.png") 2x) 0 0, pointer; /* Webkit */
    text-indent: -99999px;
}
.slick-next {
    right: 0;
    cursor: url("../images/arrow-right.png") 0 0, pointer;
    cursor: -webkit-image-set(url("../images/arrow-right.png") 1x, url("../images/arrow-right@2x.png") 2x) 0 0, pointer;
    text-indent: 99999px;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    cursor: default;
    z-index: -1;
}
.slick-prev:before,
.slick-next:before {
    display: none;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 2.6em;
}

.slick-dots {
    position: absolute;
    bottom: -38px;
    display: block;
    width: 100%;
    padding: 0 1.75em 0 0;
    margin: 0;
    list-style: none;
    text-align: right;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 4px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 9px;
    height: 9px;
    padding: 3px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:before {
    /*font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;*/
    content: "";
    width: 9px;
    height: 9px;
    display: block;
    background: #b3b3b3;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}
.slick-dots li.slick-active button:before {
    /*opacity: .75;
    color: black;*/
    background: #bfbfbf;
    background: rgba(0, 0, 0, 0.25);
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    /*opacity: 1*/;
    background: #bfbfbf;
    background: rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 991px) {

  .slick-dots {
    bottom: -32px;
    padding: 0;
  }

}

@media screen and (max-width: 767px) {

  .slick-slide img {
    height: 60vw;
  }

  .slick-slide>div {
    width: 90vw;
  }

}

@media (hover: none) { 

  .slick-prev,
  .slick-next {
    display: none !important;
    z-index: -1;
  }

}

