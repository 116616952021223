/** GENERAL **/
section.split {
	
}

section.text {

}

section.image {
	width: auto;
	max-width: 100%;
	text-align: center;
	/*.full {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		height: inherit;
		display: block;
	}*/
}

.bg-img {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	align-self: stretch;
}

section.accordion {
	h3 {
		max-width: 70%;
		margin: 0 auto;
		padding-top: 2.4em;
		padding-bottom: 0.5em;
	}
	.more {
		padding-bottom: 3.1em;
		transition: padding 200ms linear;
	}
	& .active .more {
		padding-top: 0.5em;
		padding-bottom: 3.9em;
	}
}

/*.content section.menus ul li {
	padding: 0;
	margin: 0;
}
.content section.menus ul li:before {
	display: none;
}*/

.text ul li,
.accordion ul li {
	margin-left: 1em;
}
.text ul li:before,
.accordion ul li:before {
	content: "– ";
	padding-right: 0.25em;
	margin-left: -1em;
	display: inline-block;
}

/** HOME **/

#home {
	background-image: url(../images/scallop.png);
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: contain;
	padding-bottom: 26.25vw;
}


/** BOOKINGS **/
.booking {
	display: none;
	background-color: inherit;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 40px solid #fff;
	z-index: 1060;
	opacity: 1;
	.row {
		height: calc(100vh - 82px);
	}
}

.ot-widget-container0,
.ot-dtp-picker {
	font-family: $font-regular;
	font-weight: 400 !important;
	font-size: 16px;
	line-height: 20px;
	margin: 0 auto !important;
}

.reservations-widget h1 {
	margin-top: 0;
	margin-bottom: 1em;
}
.reservations-widget h2 {
	margin: 40px auto 30px auto !important;
	position: initial;
}

/*.ot-subtitle {
	font-family: $font-bold;
	font-weight: 500;
	margin: 0 auto 40px auto;
}*/

/*#resTime option {
    display: none;
}*/

.reservations-widget {
	font-family: $font-regular !important;
	width: 350px;
	margin: 0 auto;
}

.ui-widget {
	font-family: $font-regular;
	font-weight: 400 !important;
}

.ui-widget-header,
.ui-datepicker th {
	font-weight: 400;
}

.ui-widget.ui-widget-content {
	border: 1px solid #979797;
}

.ui-datepicker .ui-datepicker-header {
	background: #fff;
	border: none;
}

input,
select {
	font-family: $font-regular;
	width: 100%;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: 1px solid #979797;
	background-color: #fff;
	margin-top: 10px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-br, .ui-corner-right {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.ui-datepicker {
	width: 100%;
}

.ui-datepicker table {
	border-collapse: collapse;
}
.ui-datepicker td {
	padding: 0;
	width: 14.28%;
	border: 1px solid #ddd;
}
.ui-datepicker td a, .ui-datepicker td span {
	padding: 0.5em 0.2em;

}
.ui-button, .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, html .ui-button.ui-state-disabled:active, html .ui-button.ui-state-disabled:hover {
	/*background-color: #fff;*/
	border: none;
}
.ui-button.ui-state-active:hover, .ui-button:active, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active {
	background: $brand-primary;
	color: #fff;
}

.ot-button {
	background-color: $brand-primary;
	margin-bottom: 20px;
	cursor: pointer;
	opacity: 1;
	-webkit-backface-visibility: hidden;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: none;
	color: #fff;
	transition: opacity 250ms;
}

.ot-button:hover {
	opacity: 0.9;
}

.ot-powered-by {
	height: 24px;
	background-image: url('../images/opentable.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 107px 24px;
	margin-top: 12px;
}




 /*.expand {
	display: none;
	margin-top: 0;
 }
 .expand div p:last-child {
 	margin-bottom: 0;
 }*/


/** MEDIA QUERIES **/

	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-only(md) {


	}

	@include media-breakpoint-down(md) {



		section.split .container {
			padding-top: 15%;
			padding-bottom: 10%;
			p.smaller:last-child a {
				text-decoration: underline;
			}
		}

		section.text .container {
			padding: 15% 0 7%;
		}

		/*section.accordion {
			h3 {
				max-width: 100%;
				padding-top: 2.4em;
				padding-bottom: .5em;
				font-size: 0.833em;
			}
			.card-body.smaller {
				font-size: 12px;
			}
		}*/

		section.menus .row > div {
			padding: 15% 0 13%;
			li {
				a {
					border: none;
					display: block;
				}
				img {
					width: 100%;
					max-width: 285px;
					height: auto;
				}
			}
		}

		.booking {
			border-width: 14px;
			.row {
				height: auto;
			}
		}

		.reservations-widget {
			width: 316px;
		}

	}

	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {

		/*.text blockquote {
			font-size: 1.7em;
			text-align: left;
		}*/

		.reservations-widget {
			width: 228px;
		}

	}

	/* Tablet // bootstrap devices 768px and over ----------- */
	@include media-breakpoint-up(md) {

		section.menus > .row img {

		}



	}

	/* Laptop // bootstrap large devices 992px and over ----------- */
	@include media-breakpoint-up(lg) {

		section.section {
			.row {
				position: relative;
			}
			.row > div {
				position: initial;
			}
			/*.txt .container {
				padding-top: 2em;
			}*/
		}

		/*section.container > .row,
		section.container-fluid > .row,
		li.container-fluid > .row*/
		section.split > .row {
			/*min-height: 93vh;*/
			.container {
				padding-top: 8%;
				padding-bottom: 8%;
			}
			& > .txt:first-child {

			}
			& > .txt:last-child {
				.container {
					padding-left: 0;
				}
			}
		}

		section.image > .row {
			/*min-height: 93vh;*/
		}

		section.text > .row {
			padding: 8% 0 3.5%;
		}

		/*section.accordion > .row {
			padding: 8% 0 8%;
		}*/

		section.menus > .row {
			padding: 8.5% 0 13%;
			/*li {
				display: inline-block;
				-webkit-backface-visibility: hidden;
				transition: opacity 500ms linear;
				&:hover img {
					opacity: 1;
				}
				&:nth-child(2):after,
				&:last-child:after {
					content: "";
				}
				a {
					display: block;
				}
				&:nth-child(4) a {padding-left: 0;}
				&:last-child a   {padding-right: 0;}

				&:nth-child(1) img {transform: translate(-49%, -75%);}
				&:nth-child(2) img {transform: translate( 52%, -75%);}
				&:nth-child(4) img {transform: translate(-74%,  12%);}
				&:nth-child(5) img {transform: translate( 34%,  12%);}
				&:nth-child(6) img {transform: translate( 51%,  12%);}
			}*/
			/*img {
				width: 200px;
				height: auto;
				-webkit-backface-visibility: hidden;
				transition: opacity 500ms linear;
			}*/
			ul {
				padding: 0 2.5%;
			}
			a {
				border-left: 1px solid #fff;
				display: block;
				/*height: 186px;
				display: flex;*/
			}
			li:last-child a {
				border-right: 1px solid #fff;
			}
			/*img {
				padding: 0 7.5% 0 32.5%;
				align-self: center;
				justify-self: center;
			}*/
		}


		.bg-img {
			min-height: 88vh;
		}


	}	

	@include media-breakpoint-up(xl) {

		section.menus > .row img {

		}

	}

	@media screen and (max-width: 1600px) {
				
	
	}

	@media screen and (min-width: 1200px) and (max-width: 1700px) {

		
	}

	@media screen and (min-width: 1700px) {
		

	}