/* Grid Settings */
$spacer:					        1em;
$enable-flex:           	true;
$main-sm-columns:			    12;
$sidebar-sm-columns:		  6;
$grid-gutter-width-base:	38px;
$grid-gutter-width-half:	19px;
//$container-padding:     9%;
$container-padding:       4.5%;

/* Colours */
$bg-color:                #FFF;
$brand-primary:				    #A48445;
$text-color:				      #4A4A4A;
$dark-color:				      #4A4A4A;
$light-color:				      #4A4A4A;
$link-color:				      #A48445;
$link-hover-decoration: 	underline;
$border:					        1px solid #ccc;
//$alt-bg:					      #f4faf9;

/* Typography */
$font-family-base:        "LL Brown Regular", Raleway, Helvetica, Arial, sans-serif;
$font-family-sans-serif:  "LL Brown Regular", Raleway, Helvetica, Arial, sans-serif;
$font-regular:            "LL Brown Regular", Raleway, Helvetica, Arial, sans-serif;
$font-bold:               "LL Brown Bold", Raleway, Helvetica, Arial, sans-serif;
$line-height-base:         1.6;

/* Navbar */
/*$navbar-height:					  230px;*/
/*$navbar-default-bg:				  transparent;*/
$navbar-default-link-color:			  $text-color;
$navbar-default-link-hover-color:	  $dark-color;
$navbar-default-link-active-color:    $dark-color;
/*$navbar-padding-vertical:			  20px;
$nav-link-padding:					  20px 28px 10px 0;*/

/*$navbar-default-toggle-border-color:transparent;
$navbar-default-toggle-hover-bg: 	  transparent;
$navbar-default-toggle-icon-bar-bg:   #1f2023;*/

/* MIX INS */

@mixin text-indent {
  text-indent: 			100%;
  white-space: 			nowrap;
  overflow: 			hidden;	  
  font-size: 			1px;
}