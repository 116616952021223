/** WOOCOMMERCE **/
.woocommerce .product_meta,
.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
	display: none;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
	background-color: transparent;
	color: $text-color;
}

.woocommerce div.product .product_title {
	text-indent: 0;
	background: none;
}

.woocommerce .col-1,
.woocommerce .col-2 {
	-ms-flex: 0 0 48%;
	flex: 0 0 48%;
	max-width: 48%;
}

/*
	.grid { 
	  h3 {
	    margin: 0.3em 0 0.95em;
	  }
	}

	.grid img {
	  background-repeat: no-repeat;
	  background-size: cover;
	  background-position: center top;
	}

	.single-post .entry-title {
	  max-width: 	680px;
	}
	.entry-content p,
	.entry-content li {
	  padding-right: 2.5em;
	}
*/

/* POST NAV */
/*
	.posts-navigation {
		font-family: $font-family-sans-serif;
	  font-size: 1.308em;
	  padding-top: 4%;
	}

	.posts-navigation p {
	  padding-top: 1.5%;
	}
	.posts-navigation p:last-child,
	.posts-navigation .nav-previous {
	  text-align: right;
	  float: right;
	}

	.grid .malinky-ajax-pagination-loading img {
	  width: 24px;
	  height: 24px;
	  display: none;
	}
	.grid .malinky-ajax-pagination-loading,
	.malinky-load-more {
	  padding: 2.5em 0 2.2em;
	  text-align: left;
	}
*/

/* MEDIA QUERIES */

	/* Desktop // bootstrap extra large devices 1200px and up ----------- */
	@include media-breakpoint-up(xl) {
		
	
	}	

	/* Laptop // bootstrap large devices less than 1200px ----------- */
	@include media-breakpoint-down(lg) {
		


	
	}	
	
	/* Laptop // bootstrap large devices 992px or more ----------- */
	@include media-breakpoint-up(lg) {
/*
		.grid { 
		  h3 {
		    margin: 0.75em 3em 0.6em 0;
		    min-height: 105px;
		  }

		  p {
		  	margin-right: 3em;
		  	max-width: 550px;
		  }

		  .more {
		  	padding: 0.55em 0 0.75em;
		  	opacity: 0;
		  	transition: opacity 300ms;
		  }

		  a:hover .more {
		  	opacity: 1;
		  }
	    }

		.single aside p {
		  margin-bottom: 1.2em !important;
		}
		.single aside a:hover {
		  color: $text-color;
		  border-color: $text-color;
		  transition: all 250ms;
		}
*/
	}

	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-down(md) {
/*
		.blog .grid h3 {
	      font-size: 1.462em;
	      margin: 0.55em 0 0.2em;
	    }

	    .blog .grid a {
	      padding-bottom: 1.7em;
	    }

	    .single-projects {
	      h3 {
	        font-size: 1.462em;
	      }
	      .smaller.dark {
	        padding-top: 0.8em;
	        padding-bottom: 0.5em;
	        a {
	          border: none;
	        }
	      }
	      aside {
	      	padding-bottom: 0.25em;
	      }
	    }
*/		
	}	

	/* Tablet // bootstrap medium devices 768px and up ----------- */
	@include media-breakpoint-up(md) {
		

	}

	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {
/*
		.portrait {
			padding-right: 10px;
		}
		.portrait+.portrait {
			padding: 0 20px 0 10px;
		}
		
		.posts-navigation > div {
		  padding: 0 12px;
		}	
		.view {
		  margin: 8% auto 7.5% auto;
		  text-align: center;
		}
*/		
	}
